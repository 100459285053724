.dotlottie {
    margin-right: -150px;
    margin-top: 80px;
    width: 570px !important;
    height: 570px !important;
    max-width: 700px !important;
    max-height: 700px !important;
}

.phraseWidth {
    max-width: 400px;
    white-space: normal;
    margin-top: -45px !important;
}